<template>
  <v-container fluid class="pa-4" ref="container">
    <v-btn fab absolute depressed top right @click="printPage" class="mt-10">
      <v-icon large>mdi-printer</v-icon>
    </v-btn>
    <v-row>
      <v-col :cols="isSmallScreen ? 12 : 2">
        <v-img
          src="@/assets/images/cpdCertified.svg"
          contain
          max-height="200px"
        />
      </v-col>
      <v-col :cols="isSmallScreen ? 12 : 10" class="px-5">
        <v-row align="center" class="fill-height" no-gutters>
          <div>
            <p class="text-h4 mb-0">{{ $t("certificationPage.title") }}</p>
            <p class="body-1 mt-4">
              {{ $t("certificationPage.description", { "0": currentDate }) }}
            </p>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <history-table class="mt-4" :dashboard="false" style="width: 90%" />
    </v-row>
  </v-container>
</template>

<script>
import HistoryTable from "@/components/common/assessment-history/AssessmentHistoryTable.vue";

export default {
  name: "Certification",
  components: {
    HistoryTable
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    currentDate() {
      var today = new Date();
      return (
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear()
      );
    }
  },
  methods: {
    async printPage() {
      window.print();
    }
  }
};
</script>
