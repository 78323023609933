var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { ref: "container", staticClass: "pa-4", attrs: { fluid: "" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "mt-10",
          attrs: { fab: "", absolute: "", depressed: "", top: "", right: "" },
          on: { click: _vm.printPage }
        },
        [_c("v-icon", { attrs: { large: "" } }, [_vm._v("mdi-printer")])],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: _vm.isSmallScreen ? 12 : 2 } },
            [
              _c("v-img", {
                attrs: {
                  src: require("@/assets/images/cpdCertified.svg"),
                  contain: "",
                  "max-height": "200px"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-5",
              attrs: { cols: _vm.isSmallScreen ? 12 : 10 }
            },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  attrs: { align: "center", "no-gutters": "" }
                },
                [
                  _c("div", [
                    _c("p", { staticClass: "text-h4 mb-0" }, [
                      _vm._v(_vm._s(_vm.$t("certificationPage.title")))
                    ]),
                    _c("p", { staticClass: "body-1 mt-4" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("certificationPage.description", {
                              "0": _vm.currentDate
                            })
                          ) +
                          " "
                      )
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c("history-table", {
            staticClass: "mt-4",
            staticStyle: { width: "90%" },
            attrs: { dashboard: false }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }